<template>
  <div class="Readily">
    <div class="nav">
      首页  >
      <span class="click" @click="$go(`/ConsultingList?type=${param.type}`)">{{
        param.type
      }}</span>
      > 发表内容
    </div>
    <div class="content">
      <titleStyle title="发表内容" />
      <component
        :is="path"
        ref="editOrCreate"
        style="background: #fff;"
        :form-type="formType"
        :method-path="methodPath"
        @reFresh="reFresh"
      />
    </div>
  </div>
</template>

<script>
import titleStyle from '@/components/titleStyle/index.vue'
export default {
  components: { titleStyle },
  data() {
    return {
      methodPath: { // 接口必传
        create: 'home/ConsultingCreate' // 创建接口
      },
      path: () => import('@/components/tableComponent/editOrCreate.vue'), // 编辑页面,如需要自定义可以引用自己的文件
      param: { type: '' },
      formType: {
        title: {
          label: '标题',
          message: '请输入标题'
        },
        type: { label: '类型', default: '', disabled: true },
        context: { // 富文本
          label: '详细内容',
          type: 'rich'
        }

      }

    }
  },
  watch: {
    $route() {
      this.getInit()
    }
  },

  mounted() {

  },
  created() {
    this.getInit()
  },

  methods: {
    reFresh() {
      this.$go(`/ConsultingList?type=${this.param.type}`)
    },
    getInit() {
      this.param = this.$getParams()
      this.formType.type.default = this.param.type
    }
  }
}
</script>

<style lang="scss" scoped>
.nav,
span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  width: 1300px;
  margin: 20px auto 19px auto;
}
  .Readily{
    background: #f5f9fa;
    flex: 1;
    .content{
      display: flex;
      flex-direction: column;
      width: 1300px;
      background: #fff;
      margin: 0px auto 118px auto;
      padding: 30px 0 87px 32px;
    }
  }
</style>
